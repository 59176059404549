<template>
  <RevCard class="!bg-surface-brand-hi flex flex-col p-16">
    <p class="body-2 mb-18 flex items-center gap-8">
      <IconCrossInCircle class="text-static-brand-mid" />
      <span>{{ model }}</span>
    </p>
    <h3 class="body-1-bold">
      {{ i18n(translations.title) }}
    </h3>
    <p class="body-2 mb-16">
      {{ i18n(translations.content) }}
    </p>

    <RevButton
      data-qa="swap-scarab-button"
      full-width="always"
      variant="secondary"
      @click="$emit('sell-other')"
    >
      {{ i18n(translations.buttonSell) }}
    </RevButton>
  </RevCard>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { IconCrossInCircle } from '@ds/icons/IconCrossInCircle'

import translations from './NoOfferV2.translations'

const i18n = useI18n()

defineProps<{
  model: string
}>()

defineEmits<{
  (e: 'sell-other'): void
}>()
</script>
